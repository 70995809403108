<script>
    import { getRandomColor } from '../utils.js';
</script>
  
<style>
    .item1 { grid-area: 1 / 1 / 6 / 5; }
    .item2 { grid-area: 1 / 5 / 6 / 8; }
    .item3 { grid-area: 1 / 8 / 6 / 11; }
    .item4 { grid-area: 6 / 1 / 11 / 4; }
    .item5 { grid-area: 6 / 4 / 9 / 8; }
    .item6 { grid-area: 9 / 4 / 11 / 8; }
    .item7 { grid-area: 6 / 8 / 11 / 11; } 
</style>
  
<div class="card-layout">
    <div class="grid-item item1" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item2" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item3" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item4" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item5" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item6" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item7" style="background-color: {getRandomColor()};"></div>
</div>
