<!-- GridManager.svelte -->
<script>
  import Toegankelijk from "./grids/Toegankelijk.svelte";
  import Uitdagend from "./grids/Uitdagend.svelte";
  import Simpel from "./grids/Simpel.svelte";
  import Dashboard from "./grids/Dashboard.svelte";
  import Mozaiek from "./grids/Mozaiek.svelte";
  import Apple from "./grids/Apple.svelte";
  import Innovatief from "./grids/Innovatief.svelte";
  import Dynamisch from "./grids/Dynamisch.svelte";
  import Harmonie from "./grids/Harmonie.svelte";
  import Elegant from "./grids/Elegant.svelte";
  import Avontuurlijk from "./grids/Avontuurlijk.svelte";
  import Zen from "./grids/Zen.svelte";
  import Speels from "./grids/Speels.svelte";

  // Array of grid components en hun code
  let grids = [
    {
      component: Toegankelijk,
      name: "Toegankelijk",
      html: `<div class="card-layout">
                <div class="grid-item item1" style="background-color: #color1;"></div>
                <div class="grid-item item2" style="background-color: #color2;"></div>
                <div class="grid-item item3" style="background-color: #color3;"></div>
                <div class="grid-item item4" style="background-color: #color4;"></div>
                <div class="grid-item item5" style="background-color: #color5;"></div>
             </div>`,
      css: `.item1 { grid-area: 1 / 1 / 6 / 7; }
             .item2 { grid-area: 6 / 1 / 11 / 3; }
             .item3 { grid-area: 6 / 3 / 11 / 5; }
             .item4 { grid-area: 6 / 5 / 11 / 11; }
             .item5 { grid-area: 1 / 7 / 6 / 11; }`
    }
    // ... andere grids ...
  ];

  // Array of card components
  let gridComponents = [
    Toegankelijk,
    Uitdagend,
    Simpel,
    Dashboard,
    Mozaiek,
    Apple,
    Innovatief,
    Dynamisch,
    Harmonie,
    Elegant,
    Avontuurlijk,
    Zen,
    Speels
  ];
  let currentGridIndex = 0;

  // Cycle through cards
  export function nextCard() {
    currentGridIndex = (currentGridIndex + 1) % gridComponents.length;
  }

  // Functie om de huidige gridnaam te krijgen
  export function getCurrentGridName() {
    let component = gridComponents[currentGridIndex];
    return component.name || "Naamloze Grid";
  }

  // export function getCurrentGridName() {
  //   let currentGrid = grids[currentGridIndex];
  //   return currentGrid.name;
  // }

  export function getCurrentGridCode() {
    let currentGrid = grids[currentGridIndex];
    return `HTML: ${currentGrid.html}\n\nCSS: ${currentGrid.css}`;
  }
</script>

<!-- Render the current card -->
<svelte:component this={gridComponents[currentGridIndex]} />
<!-- <svelte:component this={grids[currentGridIndex].component}/> -->
