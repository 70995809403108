<script>
  import { slide } from "svelte/transition";

  let isDarkMode = true;

  export let showModal = false;
  export let close;

  // Toggle tussen Dark en Light mode
  function toggleDarkMode() {
    isDarkMode = !isDarkMode;
    document.body.style.backgroundColor = isDarkMode ? "#21202d" : "#FFF";
  }
</script>

{#if showModal}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="modal-overlay" on:click={close}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="modal"
      in:slide={{ duration: 400 }}
      out:slide={{ duration: 300 }}
      on:click|stopPropagation
    >
      <header>
        <img
          src="icon-darkmode-white.svg"
          alt="Dark/Light Mode"
          class="icon darkModeIcon"
          on:click={toggleDarkMode}
        />
        <img
          src="icon-language-white.svg"
          alt="Language"
          class="icon languageIcon"
        />
        <button class="close" on:click={close}>Close</button>
      </header>
      <section>
        <h2>De kunst van bento grids</h2>
        <p>
          Bento Grids zijn geïnspireerd door Japanse lunchboxen met
          verschillende vakjes en zijn erg populair in UI-design om informatie
          aantrekkelijk en handig te ordenen. Deze webapp helpt je inspiratie op
          te doen voor grid layouts en je kunt de code direct downloaden voor je
          eigen projecten.
        </p>
        <h3>Het verhaal achter de schermen</h3>
        <p>
          Hey, ik ben Amir Fahimnia, een webdesigner en ontwikkelaar uit
          Nederland. Dit project komt voort uit mijn ervaring met Bento Grids.
          Mijn doel is om anderen te inspireren en te leren hoe je deze stijl en
          trend effectief kunt toepassen. De website is ontwikkeld met Svelte en
          draait op Firebase, voor een snelle en gebruikersvriendelijke
          ervaring.
        </p>
      </section>
    </div>
  </div>
{/if}

<style>
  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .darkModeIcon {
    margin-left: 20px;
  }

  .languageIcon {
    margin-left: 20px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    margin-left: -30px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background-color: #0e1522;
    border-radius: 20px;
    width: 50vw;
    color: #fff;
    position: relative;
    font-family: "Sora", sans-serif;
  }

  .modal p {
    font-weight: 200;
    line-height: 25px;
  }

  .modal header {
    display: flex;
    align-items: center;
    background-color: #1b2538 !important;
    height: 50px;
    border-radius: 20px 20px 0 0;
  }

  .modal section {
    padding: 20px;
  }

  .close {
    border-radius: 20px;
    padding: 4px 12px;
    background-color: #fff;
    color: #000;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
