<!-- App.svelte -->
<script>
  import GridManager from "./GridManager.svelte";
  import Modal from "./Modal.svelte";
  import CodeModal from "./CodeModal.svelte";

  let isDarkMode = true;
  let showModal = false;
  let showCodeModal = false;

  let gridManager;
  let currentGridName = "";
  let currentCode = "";
  let firstGridSelected = false;

  function toggleCodeModal() {
    if (gridManager && typeof gridManager.getCurrentGridCode === "function") {
      currentGridName = gridManager.getCurrentGridName();
      currentCode = gridManager.getCurrentGridCode();
      showCodeModal = !showCodeModal;
    } else {
      console.error(
        "Functie getCurrentGridCode is niet gevonden in gridManager"
      );
    }
  }

  function toggleModal() {
    setTimeout(() => {
      showModal = !showModal;
    }, 10);
  }

  // Functie om de gridnaam bij te werken
  function updateGridName() {
    if (!firstGridSelected) {
      firstGridSelected = true;
    }
    gridManager.nextCard();
    currentGridName = gridManager.getCurrentGridName();
  }

  $: console.log("Modal visibility:", showModal);
</script>

<main>
  <div class="top-bar">
    <img
      src={isDarkMode ? "/logo-bentogrid-white.svg" : "/logo-bentogrid.svg"}
      alt="Logo"
      class="logo"
    />

    <div class="gridNameContainer">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class={firstGridSelected ? "grid-name" : "hidden"}
        >{currentGridName}
        <img
          src={isDarkMode ? "/icon-download-white.svg" : "/icon-download.svg"}
          alt="Download"
          class={firstGridSelected ? "downloadIcon" : "hidden"}
          on:click={toggleCodeModal}
        />
      </span>
    </div>

    {#if showCodeModal}
      <!-- svelte-ignore missing-declaration -->
      <CodeModal {currentCode} close={toggleCodeModal} />
    {/if}

    <div class="nav-section">
      <button on:click={updateGridName} class="next"> Next Grid</button>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <img
        src="/icon-menu-white.svg"
        alt="Menu icon"
        class="icon-right"
        on:click={toggleModal}
      />
    </div>
  </div>
  <!-- Modal Component -->
  <Modal {showModal} close={toggleModal} />
  <GridManager bind:this={gridManager} />
</main>
