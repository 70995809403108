<!-- CodeModal.svelte -->
<script>
import {
    slide
} from 'svelte/transition';

export let currentCode = "";
export let close;

</script>
  
  {#if currentCode}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="code-modal-overlay" on:click={close}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="code-modal" in:slide={{ duration: 400 }} out:slide={{ duration: 300 }}  on:click|stopPropagation>
        <button class="close" on:click={close}>Close</button>
        <pre><code>{currentCode}</code></pre>
    </div>
</div>
{/if}
  
  <style>
code {
    font-family: monospace;
    white-space: pre;
    /* Overige stijling voor code */
}

.code-modal-overlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    margin-left: -20px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.code-modal {
    background-color: #0e1522;
    padding: 20px;
    border-radius: 20px;
    width: 50vw;
    color: #fff;
    position: relative;
    font-family: "Sora", sans-serif;
}


.close {
    border-radius: 20px;
    padding: 5px 15px;
    background-color: #a7ff95;
    color: #000;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
