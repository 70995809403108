<script>
    import { getRandomColor } from '../utils.js';
</script>
  
<style>
    .item1 { grid-area: 1 / 1 / 4 / 5; }
    .item2 { grid-area: 4 / 1 / 6 / 5; }
    .item3 { grid-area: 6 / 1 / 11 / 3; }
    .item4 { grid-area: 6 / 3 / 8 / 5; }
    .item5 { grid-area: 8 / 3 / 11 / 5; }
    .item6 { grid-area: 1 / 5 / 3 / 8; }
    .item7 { grid-area: 3 / 5 / 8 / 8; }
    .item8 { grid-area: 8 / 5 / 11 / 8; }
    .item9 { grid-area: 1 / 8 / 4 / 11; }
    .item10 { grid-area: 4 / 8 / 6 / 10; }
    .item11 { grid-area: 4 / 10 / 6 / 11; }
    .item12 { grid-area: 6 / 8 / 9 / 11; }
    .item13 { grid-area: 9 / 8 / 11 / 11; } 
</style>
  
<div class="card-layout">
    <div class="grid-item item1" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item2" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item3" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item4" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item5" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item6" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item7" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item8" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item9" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item10" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item11" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item12" style="background-color: {getRandomColor()};"></div>
    <div class="grid-item item13" style="background-color: {getRandomColor()};"></div>
</div>
